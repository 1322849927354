<template>
  <div>
    <b-card no-body>
      <div class="section-btn-chat__top">
        <b-button
          :variant="activeUserId === deal.buyer.id ? 'primary' : 'flat-primary'"
          size="lg"
          class="w-50"
          @click="activeUserId = deal.buyer.id"
        >
          Buyer - {{ deal.buyer.id }}
        </b-button>
        <b-button
          :variant="activeUserId === deal.seller.id ? 'primary' : 'flat-primary'"
          size="lg"
          class="w-50"
          @click="activeUserId = deal.seller.id"
        >
          Seller - {{ deal.seller.id }}
        </b-button>
      </div>
      <section class="chat-app-window">
        <div
          class="active-chat"
        >
          <div ref="container" class="chats">
            <div v-for="msg in deal.events" :key="msg.id" class="chat">
              <div v-if="
                msg.type === 'admin_message' ||
                msg.type === 'admin_upload_file' ||
                msg.type === 'user_message' ||
                msg.type === 'upload_file'
              "
                class="chat-body"
                :class="positionChat(msg.fromUserId, msg.toUserId)"
              >
                <div class="chat-content">
                  <b-img-lazy v-if="msg.link" :src="msg.link" fluid alt="image" />
                  <div>{{ msg.message }}</div>
                </div>
              </div>

              <div v-else class="chat-body">
                <div v-if="msg.type === 'dispute_open'" class="chat-content">
                  <div>{{ textStatus(msg.type) }}</div>
                  <b-img-lazy v-if="msg.link" :src="msg.link" fluid alt="image" />
                  <div>{{ msg.message }}</div>
                </div>
                <div v-else class="chat-content">
                  {{ textStatus(msg.type) }}
                </div>
              </div>
            </div>
          </div>

          <div class="p-1">
            <b-input-group>
              <b-input-group-prepend @change="addImage">
                <label for="test-input" class="label-for-file">
                  <div class="btn-file">
                    <feather-icon icon="UploadIcon" />
                  </div>
                  <b-form-file
                    id="test-input"
                    v-model="file"
                    class="mt-1 w-0"
                    accept="image/*"
                    plain
                  />
                </label>
              </b-input-group-prepend>
              <b-form-input v-model.trim="message" placeholder="Write message..." />
              <b-input-group-append>
                <b-button variant="outline-primary" :disabled="!message" @click="sendMessage">
                  Send
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>
      </section>

      <div class="section-btn-chat__bottom">
        <b-button
          variant="primary"
          class="w-50"
          @click="showMsgBoxTwo('release')"
        >
          Release and close
        </b-button>
        <b-button
          variant="primary"
          class="w-50"
          @click="showMsgBoxTwo('retrive')"
        >
          Retrive and close
        </b-button>
      </div>
    </b-card>
    <InfoDeal
      v-if="isLoadingDeal"
      :deal="deal"
    />
  </div>
</template>

<script>
import {
  BInputGroup,
  BFormInput,
  BButton,
  BCard,
  BInputGroupAppend,
  BInputGroupPrepend,
  BFormFile,
  BImgLazy,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import waitRequest from '@/mixins/waitRequest'
import validationError from '@/mixins/validationError'
import InfoDeal from './components/infoDeal.vue'
// import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  name: 'P2pDealsChat',
  components: {
    InfoDeal,
    BInputGroup,
    BFormInput,
    BButton,
    BCard,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormFile,
    BImgLazy,
    // VuePerfectScrollbar,
  },
  mixins: [waitRequest, validationError],
  data() {
    return {
      isLoadingDeal: false,
      activeChat: '',
      activeUserId: null,
      message: '',
      file: null,
    }
  },
  computed: {
    ...mapGetters({
      deal: 'deals/deal',
      dealUpdate: 'deals/dealUpdate',
      userInfo: 'auth/admin',
    }),
    eventsList() {
      const arr = this.deal.events.map(el => el.type)
      const id = arr.indexOf('dispute_open')
      const eventList = this.deal.events.slice(id)
      return eventList
    },
  },
  watch: {
    deal() {
      this.isLoadingDeal = true
      this.activeUserId = this.activeUserId ? this.activeUserId : this.deal.buyer.id
      if (this.deal.status !== 'dispute_open' || this.userInfo.id !== this.deal.responsible.id) this.$router.push({ name: 'p2p-deals' })
    },
    dealUpdate() {
      if (this.dealUpdate.deal.dealId === this.deal.id) {
        this.fetchDeal()
      }
    },
  },
  mounted() {
    this.fetchDeal()
    setTimeout(() => {
      this.scrollToBottom()
    }, 500)
  },
  updated() {
    this.scrollToBottom()
  },
  methods: {
    ...mapActions({
      fetchDealById: 'deals/fetchDealById',
      sendAdminMessage: 'deals/sendAdminMessage',
      returnMoney: 'deals/returnMoney',
      sendMoney: 'deals/sendMoney',
    }),
    positionChat(fromId, toId) {
      if (fromId === this.activeUserId) return 'left'
      if (fromId === this.userInfo.id) {
        if (toId === this.activeUserId) return 'right'
        return 'hidden-oponent'
      }
      if (fromId) {
        if (toId === this.activeUserId) return 'right oponent'
        return 'hidden-oponent'
      }
      return ''
    },
    textStatus(type) {
      const texts = {
        deal_open: 'Deal opened',
        money_sent: 'Money sent',
        deal_completed: 'Deal completed',
        time_over: 'Time over',
        deal_canceled: 'Deal canceled',
        deal_time_over: 'Deal time over',
        dispute_open: 'Dispute open',
        moderator_connect: 'Moderator connected',
        moderator_decided_win_buyer: 'Moderator decided win buyer',
        moderator_decided_win_seller: 'Moderator decided win seller',
      }
      return texts[type]
    },
    scrollToBottom() {
      this.$refs.container.scrollTop = this.$refs.container.scrollHeight
    },
    fetchDeal() {
      const dealId = this.$route.params.id
      this.waitRequest(() => this.fetchDealById(dealId).catch(this.checkErrors))
    },
    addImage(file) {
      if (!file) return
      if (file.size > 5000000) {
        this.$bvToast.toast('Max size image 5MB', {
          title: 'Error size image',
          variant: 'danger',
          solid: true,
        })
        return
      }
      this.sendMessage()
    },
    sendServer(form) {
      return this.waitRequest(() => this.sendAdminMessage(form).catch(error => this.customError(error)))
    },
    customError(error) {
      const errors = error.response?.data.message
      let msg
      Object.keys(errors).forEach(el => {
        msg = Object.values(errors[el]).join(', ')
      })
      this.$swal({
        title: `Error ${error.response?.status}`,
        text: `${msg}`,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    sendMessage() {
      const form = new FormData()
      form.append('dealId', this.deal.id)
      form.append('toUserId', this.activeUserId)
      form.append('message', this.message)
      form.append('file', this.file)
      this.sendServer(form).then(() => {
        this.message = ''
        this.file = null
        this.fetchDeal()
      })
    },
    retriveMoney() {
      return this.waitRequest(() => this.returnMoney({ dealId: this.deal.id }).catch(this.checkErrors))
    },
    releaseMoney() {
      return this.waitRequest(() => this.sendMoney({ dealId: this.deal.id }).catch(this.checkErrors))
    },
    typeTextModal(type) {
      return type === 'release' ? 'Release and close' : 'Retrive and close'
    },
    showMsgBoxTwo(type) {
      this.boxTwo = ''
      this.$bvModal
        .msgBoxConfirm(`Please confirm ${this.typeTextModal(type)}. This action cannot be changed.`, {
          title: 'Release',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Confirm',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            type === 'release' ? this.releaseMoney().then(() => this.fetchDeal()) : this.retriveMoney().then(() => this.fetchDeal())
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-chat.scss';
@import '~@core/scss/base/pages/app-chat-list.scss';
$chat-head-footer-height: 65px;
.chat-app-window .chat-app-form {
  height: 130px;
}

.chat-app-window .user-chats {
  height: calc(100% - 65px - 130px);
}

.chat-app-window .user-chats {
  background-color: #e3e3e3e3;
  padding: 1rem;
  position: relative;
}
.chat-app-window .chats {
  height: 60vh;
  overflow: auto;
}
.section-btn-chat {
  padding: 0 1rem;
  &__top {
    padding: 0.5rem;
    margin-bottom: 1rem;
  }
  &__bottom {
    display: flex;
    gap: 1rem;
    padding: 1rem;
  }
}
.btn-file {
  position: relative;
  padding: 0.65rem 1.5rem;
  color: #7367f0;
  border: 1px solid #7367f0 ;
  background-color: transparent;
  border-radius: 0.358rem 0  0 0.358rem;
  z-index: 2;
  cursor: pointer;
  &:hover {
    background-color: rgba(115, 103, 240, 0.04);
  }
}
.label-for-file {
  margin: 0;
}
.w-0 {
  max-width: 0;
  position: absolute;
  opacity: 0;
}
</style>
